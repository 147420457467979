const Enviroment = {
  URL: 'https://app.comitemujeresacit.org',
  //URL: 'http://localhost:8080',
  RESERVE_KEYS: [
    'acit-token',
    'acit-poll-list',
    'acit-poll-',
    'acit-poll-last-date',
  ],
};

export default Enviroment;
