import "react-image-gallery/styles/css/image-gallery.css"
import ImageGallery from 'react-image-gallery';
import Button from "../../components/CustomButtons/Button";
import React, { useEffect } from "react";
import LibraryService from "service/LibraryService";

const GalleryDetail = ({gallery, onCloseGallery}) => {

    const [files, setFiles] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const [selectedImage, setSelectedImage] = React.useState(0);

    useEffect(() => {
      LibraryService.getFiles(gallery.id).then((response) => {
        if(response.status === 200) {
          response.json().then(json => {
            setSelectedImage(0);
            setImages(json.content);
            const images = json.content.map(item => {
              return {original: "https://storage.googleapis.com/acit/acit/"+item.id+item.extension, thumbnail: "https://storage.googleapis.com/acit/acit/"+item.id+item.extension}
            });
            setFiles(images);
          })
        }
      });
    }, [gallery]);

    return (
        <div className="gallery-detail-container">
          <div>
            <div className="gallery-detail-title">{gallery.name + ' - ' + images[selectedImage]?.name}</div>
          </div>
          <Button size="sm" type="button" color="info" onClick={() => onCloseGallery()}>Salir</Button>
          <ImageGallery items={files} onSlide={(currentIndex) => setSelectedImage(currentIndex) } showFullscreenButton={false} lazyLoad={true} additionalClass="gallery-class"  />
        </div>
    )
};

export default GalleryDetail;