import './Menu.css';
import hamburger from "../assets/img/dehaze_24px.png";
import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import CustomInput from "../components/CustomInput/CustomInput";
import Popover from "@material-ui/core/Popover";
import UserService from "../service/UserService";
import {sha256} from 'js-sha256';

function Menu() {
    const menuContainerRef = React.useRef();
    const [logged, setLogged] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [messageError, setMessageError] = React.useState(false);
    const [anchorElRight, setAnchorElRight] = React.useState(null);

    const POLL = [
        {id: "5f6d31868949473c032d5011", name: "Encuesta de Caracterización"},
        {id: "5f727175d7277cc078f40258", name: "Acompañamiento"},
        {id: "6038126acafeab9fbe83f462", name: "Talleres de formación "},
        {id: "603812e1cafeab9fbe83f463", name: "Conversatorios"},
    ]

    const MENU_ITEMS = [
        {key: "1", text: "Inicio", logged: false, url: '/'},
        {key: "2", text: "Inicio", logged: true, url: '/'},
        //{key: "1", text: "¿Quiénes Somos?", logged: false, url: '/about'},
        //{key: "2", text: "¿Qué Hacemos?", logged: false, url: '/we-do'},
        //{key: "3", text: "Contáctanos", logged: false, url: '/contact'},
        //{key: "4", text: "¿Quiénes Somos?", logged: true, url: '/about'},
        //{key: "5", text: "¿Qué Hacemos?", logged: true, url: '/we-do'},
        {key: "6", text: "Biblioteca", logged: false, url: '/library'},
        {key: "7", text: "Biblioteca", logged: true, url: '/library'},
        {key: "8", text: "Galería", logged: true, url: '/gallery'},
        {key: "9", text: "Galería", logged: false, url: '/gallery'},
        {key: "10", text: "Usuarias", logged: true, url: '/user'},
        {key: "11", text: "Talleres de Formación", logged: true, url: '/poll-answer/' + POLL[2].id},
        {key: "12", text: "Conversatorios", logged: true, url: '/poll-answer/' + POLL[3].id},
        {key: "13", text: "Encuestas", logged: true, url: '/poll-answer/' + POLL[0].id},
        {key: "14", text: "Acompañamientos", logged: true, url: '/poll-answer/' + POLL[1].id},
        {key: "15", text: "Mi Cuenta", logged: true, url: '/create-user/'+ localStorage.getItem('acit-id')},
        {key: "16", text: "Contáctanos", logged: false, url: '/contact'},
    ]

    useEffect(() => {
        const token = localStorage.getItem('acit-token');
        if (token) setLogged(true);
    }, []);

    const changeVisible = () => {
        menuContainerRef.current.classList.toggle('is-visible');
    }

    const login = (event) => {
        if(!logged) {
            if (email.length === 0 || password.length === 0) {
                setMessageError("El email y contraseña son obligatorios.");
                setAnchorElRight(event.currentTarget);
            } else {
                const target = event.currentTarget;
                UserService.login(email, sha256(password)).then((response) => {
                    if (response.status === 200) {
                        setLogged(true);
                        response.json().then((resp) => {
                            localStorage.setItem('acit-id', resp.id);
                            localStorage.setItem('acit-token', resp.token);
                            window.location.reload();
                        });
                    } else {
                        setMessageError("El email o contraseña es incorrecto.");
                        setAnchorElRight(target);
                    }
                }).catch(() => {
                    setMessageError('Error de conexión.');
                    setAnchorElRight(target);
                })
            }
        } else {
            localStorage.removeItem('acit-token');
            setLogged(false);
            window.location.replace('/');
        }
    }

    return (
        <>

                <div ref={menuContainerRef} className="menu-container">
                    <div className="options-container">
                        {
                            MENU_ITEMS.filter((item) => item.logged === logged).map((item) => (
                                <Link to={item.url} className="link">
                                    <div key={item.key} className="menu-item">{item.text}</div>
                                </Link>
                            ))
                        }
                    </div>
                    <div className="login-container">
                        <form onSubmit={(event) => login(event)}>
                            {!logged && (
                                <>
                                    <CustomInput
                                        labelText="Email"
                                        formControlProps={{className: "login-input"}}
                                        error={email.length === 0}
                                        id="email"
                                        name="email"
                                        inputProps={{onChange: (value) => setEmail(value.target.value)}}
                                    />
                                    <CustomInput
                                        labelText="Password"
                                        formControlProps={{className: "login-input"}}
                                        inputProps={{
                                            type: 'password',
                                            onChange: (value) => setPassword(value.target.value)
                                        }}
                                        error={password.length === 0}
                                    />
                                </>
                            )}
                            <input onClick={(event) => login(event)} type="button"
                                   value={logged ? "Cerrar sesión" : "Iniciar sesión"}
                                   className="login-button"/>
                            <Popover
                                classes={{
                                    //paper: classes.popover
                                }}
                                open={Boolean(anchorElRight)}
                                anchorEl={anchorElRight}
                                onClose={() => setAnchorElRight(null)}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right"
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <div>
                                    {messageError}
                                </div>
                            </Popover>
                        </form>
                    </div>
                </div>
                <div className="menu-button-group" onClick={() => changeVisible()}>
                    <div className="menu-button-title">Menú</div>
                    <img src={hamburger} alt="menu"/>
                </div>

        </>
    );
}

export default Menu;
