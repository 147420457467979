import './User.css';
import background from "../assets/img/backuser.jpg";
import Header from "../header/Header";
import Button from "../components/CustomButtons/Button";
import React from 'react';
import Table from "../table/Table";
import UserService from "../service/UserService";
import Popover from "@material-ui/core/Popover";
import UserCard from "./user-card/UserCard";
import newImage from "../assets/img/create_24px_outlined.svg";
import seeImage from "../assets/img/visibility_24px_outlined.svg";
import downloadImage from "../assets/img/download_24px_outlined.svg";

function User() {

    const [userList, setUserList] = React.useState([]);
    const [anchorElRight, setAnchorElRight] = React.useState([]);
    const refContainer = React.useRef();
    const [widthContainer, setWidthContainer] = React.useState(null);

    React.useEffect(() => {
        setWidthContainer(refContainer.current.clientWidth);
        UserService.getUsers(0).then((response) => {
            if (response.status === 200) {
                response.json().then(json => {
                    const list = json.map(() => null);
                    setAnchorElRight(list);
                    setUserList(json);
                });
            }
        });
    }, []);

    const showUser = (event, index) => {
        const list = userList.map(() => null);
        list[index] = event.target;
        setAnchorElRight(list);
    }

    const getButtons = (user, index) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} key={'button-' + user.id}>
                <Button size="sm" type="button" color="primary" onClick={(event) => showUser(event, index)}>
                    <img src={seeImage} alt={"see-image-" + user.id} />
                </Button>
                <Popover
                    classes={{
                        //paper: classes.popover
                    }}
                    open={Boolean(anchorElRight[index])}
                    anchorEl={anchorElRight[index]}
                    onClose={() => {
                        const list = userList.map(() => null);
                        setAnchorElRight(list)
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right"
                    }}
                >
                    <UserCard user={user} />
                </Popover>
                <Button size="sm" type="button" color="info" href={"/create-user/" + user.id}>
                    <img src={newImage} alt={"new-image-" + user.id} />
                </Button>
                <Button size="sm" type="button" color="info" onClick={() => {
                                        UserService.downloadUser(user.id).then(response => {
                                            response.text().then(url => {
                                                window.open("https://storage.googleapis.com/acit/" + url, '_blank');
                                            });
                                        })
                                    }}><img src={downloadImage} alt={"download-image-" + user.id} /></Button>
            </div>);
    }

    return (
        <div ref={refContainer} className="main-container">
            <img src={background} className="image-background" alt="background" />
            <Header title="Usuarias" subtitle={"Administracion de usuarias"} />
            <div className="body-content">
                <div className="user-button-div">
                    <Button
                        color="success"
                        size="lg"
                        href="/create-user/new"
                        rel="noopener noreferrer"
                        className="btnNewConversation"
                    >
                        Crear Usuaria
                    </Button>
                    <Button
                        color="success"
                        size="lg"
                        rel="noopener noreferrer"
                        className="btnNewConversation"
                        onClick={() => UserService.downloadUsers().then(response => {
                            response.text().then(url => {
                                window.open("https://storage.googleapis.com/acit/" + url, '_blank');
                            });
                        })}
                    >
                        Descargar
                    </Button>
                </div>
                <div className="tab-container">
                    <div className="table">
                        <Table
                            columnNames={
                                widthContainer < 1000 ? ["Nombre", "Apellidos", "Acciones"] :
                                    ["Nombre", "Apellidos", "Perfil", "Web", "Aplicacion", "Estado", "Acciones"]
                            }
                            data={
                                userList.map((user, index) =>
                                    widthContainer < 1000 ? [user.name, user.lastName, getButtons(user, index)] :
                                        [user.name, user.lastName, user.profileName, user.accessWeb ? "Si" : "No",
                                        user.accessApp ? "Si" : "No", user.active ? "Activo" : "Inactivo",
                                        getButtons(user)]
                                )
                            } />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default User;
