import './Contact.css';
import React from 'react';
import Header from "../header/Header";
import background from "../assets/img/backuser.jpg";
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


function Contact() {

  const { register, handleSubmit, formState:{errors}, reset} = useForm();

  const toastifySuccess = () => {
    toast('Tu mensaje fue enviado, Gracias por escribirnos, nos contactaremos contigo proximamente', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };
  
  const onSubmit = async (data) => {
    // Send form email
    try {

      const templateParams = {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message
      };

      await emailjs.send(
        //process.env.REACT_APP_SERVICE_ID,
        //process.env.REACT_APP_TEMPLATE_ID,
        'service_wnbcwaj',
        'template_tog906t',
        templateParams,
        //process.env.REACT_APP_USER_ID
        'user_6oL1JKcvf94w5vKvuWwWD'
      ).then((result) => {
        
    }, (error) => {
        
    });
      reset();
      toastifySuccess();
    } catch (e) {
      
    }
  };
 
  return (
    <div className="main-container">
      <img src={background} className="image-background" alt="background"/>
      <Header title="Contáctanos" subtitle={"Cuéntanos tús inquietudes"}/>
      <div className="body-content">
        <div className="sub-containero">
          <label className="labelSubTitle">Información que debes diligenciar</label>
          <div className='space'/>
          <div className="line"></div>
          <div className='space'/>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-input">
            <label className="inputLabel">Nombre:</label>
              <input
                type="text"
                className="inputTrin" 
                {...register("name",{
                  required: {value: true, message:'Tú nombre es obligatorio'},
                  maxLength: {value: 30, message: 'Tú nombre completo debe tener máximo 30 caracteres'},
                    })} 
                placeholder="Escribe tú nombre completo..."/>
                {errors.name && <p className="errorMessage">{errors.name.message}</p>}               
            </div>
            <div className="form-input">              
            <label htmlFor="email" className="inputLabelEmail">Email:</label>
              <input
                type='email'
                className="inputTrin"
                {...register("email",{
                  required: {value: true, message:'tú correo electronico es obligatorio'},
                  pattern: {value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'Porfavor ingresa un formato de Email correcto'}
                    })} 
                placeholder="Escribe tu correo electronico..."/>
                {errors.email && <p className="errorMessage">{errors.email.message}</p>}
                </div>
            <div className='space2'/>            
            <div className="form-input">              
            <label htmlFor="subject" className="inputLabelSubject">Asunto:</label>
              <input
                type="text"
                className="inputSubject"
                white={true} 
                {...register("subject",{
                  required: {value: true, message:'El motivo de contacto es obligatorio'},
                  maxLength: {value: 75, message: 'El motivo del mensaje no debe superar los 75 caracteres'},
                    })} 
                    placeholder="Escribe tu motivo de contacto..."/>
                {errors.subject && <p className="errorMessage">{errors.subject.message}</p>}
                </div>
                <div className='space'/>            
            <div className="form-input">
            <label htmlFor="message" className="inputLabel">Mensaje:</label>
              <textarea
                name='message'
                className="inputMessage"
                spellCheck="true"
                {...register("message",{
                  required: {value: true, message:'Escribenos algo'},
                    })} 
                placeholder="Escribe tu mensaje..."/>
                {errors.message && <p className="errorMessage">{errors.message.message}</p>}
            </div>
            <div className='space'/> 
            <input className="send-button" type="submit" />
          </form>
        </div>  
        <ToastContainer />                      
      </div>
    </div>
  );
};

export default Contact;