import './HomeAcitProp2App.css';
import background from "../assets/img/background.jpg";
import icon from "../assets/img/icon_app.png";
import Menu from "../menu/Menu";
import contact from "../assets/img/expand_more_24px.png";
import {Link} from "react-router-dom";

function HomeAcitProp2App() {
  return (
    <div className="prop-2-main-container">
      <img src={background} className="prop-2-image-background" alt="background" />
      <div className="prop-2-header">
        <Menu />
      </div>
      <div className="prop-2-name-bar">
          <div className="prop-2-title">Comité de mujeres ACIT</div>
          <div className="prop-2-sub-title">Mujeres Campesinas en junta, por un buen vivir</div>
      </div>
      <div className="prop-2-footer">
          <div className="prop-2-footer-button">
              <Link to={'/about'} className="prop-2-link">
                  <div className="prop-2-button-flex">
                      <img src={icon} alt="who" />
                      <div className="prop-2-button-text">¿Quiénes Somos?</div>
                  </div>
              </Link>
              <Link to={'/we-do'} className="prop-2-link">
                  <div className="prop-2-button-flex">
                      <img src={icon} alt="do" />
                      <div className="prop-2-button-text">¿Que Hacemos?</div>
                  </div>
              </Link>
          </div>
          <div className="prop-2-footer-button">
              <div className="prop-2-contact-button">
                  <div>Contáctanos</div>
                  <img className="prop-2-img-contact-button" src={contact} alt="contact" />
              </div>
          </div>
      </div>
    </div>
  );
}

export default HomeAcitProp2App;
