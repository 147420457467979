import './PollAnswerCard.css';
import React from 'react';
import "react-datetime/css/react-datetime.css";
import { PollAnswerService } from 'service/PollAnswerService';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import PollModuleService from 'service/PollModuleService';
import Button from 'components/CustomButtons/Button';

function a11yProps(index, scroll) {
    if (scroll)
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function PollAnswerCard(props) {

    const pollAnswer = props.pollAnswer;
    const idPoll = props.idPoll;
    const goBack = props.goBack;
    const [titles, setTitles] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [moduleIds, setModuleIds] = React.useState([]);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const refContainer = React.useRef();
    const [widthContainer, setWidthContainer] = React.useState(null);
    const [pollModules, setPollModules] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    React.useEffect(() => {
        setWidthContainer(refContainer.current.clientWidth);
        PollAnswerService.getReadData(pollAnswer.id).then(
            response => {
                response.json().then(json => {
                    setTitles(json[0]);
                    setData(json[1]);
                    setModuleIds(json[2]);
                })
            }
        )
        PollModuleService.getPollModules(idPoll).then(response => {
            response.json().then(json => {
                setPollModules(json.filter(item => item.idParent));
            })
        })
    }, [pollAnswer, idPoll])

    return (
        <div className="poll-answer-card-body" ref={refContainer}>
            <div>
                <Button size="sm" type="button" color="info" onClick={() => goBack()}>Volver</Button>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="primary"
                        variant={"scrollable"}
                        scrollButtons="on"
                        aria-label="Lineas de trabajo"
                    >
                        {
                            pollModules.map(pollModule =>
                                <Tab label={pollModule.name} style={{
                                    backgroundColor: "rgba(67, 145, 41, 0.85)",
                                    color: "#DADE8E",
                                    fontSize: 13,
                                    fontFamily: 'Comfortaa',
                                }} {...a11yProps(0, widthContainer < 800)} />
                            )
                        }
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {
                        pollModules.map((pollModule, index) =>
                            <TabPanel value={value} index={index} dir={theme.direction}>
                                <div className="user-card-detail-div">
                                    <div style={{ margin: 'auto' }}>
                                        {data.map((item, index) => {
                                            if (pollModule.id === moduleIds[index])
                                                return (<div className="card-info-poll-answer">
                                                    <div className="card-title-poll-answer">{titles[index]}</div>
                                                    <span className="card-data-poll-answer">{item}</span>
                                                </div>);
                                            else return <div />;
                                        })}
                                    </div>
                                </div>
                            </TabPanel>
                        )
                    }
                </SwipeableViews>
            </div>

        </div>
    )
}

export default PollAnswerCard;
