import './LibraryForm.css';
import background from "../../assets/img/backuser.jpg";
import deleteImage from "../../assets/img/delete_outline_24px_outlined.svg";
import Header from "../../header/Header";
import Button from "../../components/CustomButtons/Button";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from 'react';
import "react-datetime/css/react-datetime.css";
import LibraryService from "../../service/LibraryService";
import { useParams } from 'react-router-dom'
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Popover from "@material-ui/core/Popover";
import Tooltip from '@material-ui/core/Tooltip';
import { WE_DO } from 'we-do/detail/WeDoDetail';
import Table from 'table/Table';
import DeleteAlert from 'alert/DeleteAlert';
import ErrorAlert from 'alert/ErrorAlert';
import WaitAlert from 'alert/WaitAlert';


const GreenCheckbox = withStyles({
    root: {
        color: "#80CD33",
        '&$checked': {
            color: "#80CD33",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function LibraryForm(props) {

    const [library, setLibrary] = React.useState(null);
    const [messageError, setMessageError] = React.useState(false);
    const [anchorElRight, setAnchorElRight] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const fileInputRef = React.useRef();
    const { id, libraryType } = useParams();
    const nameLibrary = libraryType === "1" ? "Biblioteca" : "Galeria";
    const [idDeleteAlert, setIdDeleteAlert] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [waitAlert, setWaitAlert] = React.useState(false);

    useEffect(() => {
        if (id !== 'new') {
            LibraryService.getLibrary(id).then((response) => {
                if (response.status === 200) {
                    response.json().then(json => {
                        setLibrary(json)
                        LibraryService.getFiles(id).then((response) => {
                            if (response.status === 200) {
                                response.json().then(jsonFiles => {
                                    setFiles(jsonFiles.content)
                                })
                            }
                        })
                    });
                }
            });
        } else {
            setLibrary({ state: true, date: new Date(), libraryType: libraryType });
        }
    }, [id, libraryType]);

    const saveLibrary = (event) => {
        const newLibrary = validate();
        if (newLibrary) {
            let promise;
            if (id !== 'new') {
                promise = LibraryService.updateLibrary(id, newLibrary);
            } else {
                promise = LibraryService.createLibrary(newLibrary);
            }
            promise.then(response => {
                if (response.status === 200) {
                    if (id === 'new') {
                        response.json().then(json => props.history.push('/create-library/' + json.id + '/' + libraryType))
                    } else {
                        if(libraryType === "1") {
                            props.history.push('/library');
                        } else {
                            props.history.push('/gallery');
                        }
                    }
                } else {
                    setMessageError("Hubo un error en el guardado.");
                    setAnchorElRight(event.currentTarget);
                }
            }).catch(() => {
                setMessageError("Hubo un error en el guardado.");
                setAnchorElRight(event.currentTarget);
            });
        } else {
            setMessageError("Faltan campos obligatorios.");
            setAnchorElRight(event.currentTarget);
        }
    }

    const validate = () => {
        if (!library.name || library.name.length === 0) {
            return false;
        }
        if (!library.workLine) {
            return false;
        }
        const newLibrary = { ...library };
        if (!library.isPublic) {
            newLibrary.isPublic = false;
        }
        if (!library.state) {
            newLibrary.state = false;
        }
        setLibrary(newLibrary);
        return newLibrary;
    }

    const loadFile = () => {
        fileInputRef.current.click();
    }

    const changeFile = (event) => {
        if(event.target.files[0].size > 1024*1024*10) {
            setErrorMessage("El archivo no puede pesar más de 10MB");
        } else {
            setWaitAlert(true);
            LibraryService.uploadFile(library.id, event.target.files[0]).then(
                (response) => {
                    if (response.status === 200) {
                        setWaitAlert(false);
                        response.json().then((json) => {
                            setFiles([...files, json])
                        })
                    } else {
                        setErrorMessage("Hubo un error al cargar el archivo");
                    }
                }
            )
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DeleteAlert open={idDeleteAlert} onClose={(canDelete) => {
                if (canDelete) {
                    LibraryService.deleteFile(idDeleteAlert).then(response => {
                        if (response.status === 200) {
                            setFiles(files.filter(file => file.id !== idDeleteAlert))
                        }
                    })
                }
                setIdDeleteAlert(null)
            }} />
            <ErrorAlert message={errorMessage} open={errorMessage} onClose={() => setErrorMessage(null)} />
            <WaitAlert message={"Se está subiendo el archivo, por favor espere"} open={waitAlert} />
            <div className="main-container">
                <img src={background} className="image-background" alt="background" />
                <Header title={nameLibrary} subtitle={"Creacion nueva " + nameLibrary} />
                {library && (
                    <div className="body-content-library-create">
                        <div className="tab-container-library">
                            <form className="library-form">
                                <h5 className="titlex">Información</h5>
                                <div className="trin">
                                    <GridContainer className="grid-container">
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Nombres"
                                                id="name"
                                                name='name'
                                                white={true}
                                                inputProps={{
                                                    defaultValue: library.name,
                                                    onChange: (value) => {
                                                        const newLibrary = { ...library, name: value.target.value };
                                                        setLibrary(newLibrary);
                                                    }
                                                }}
                                                labelProps={{ required: true }}
                                                error={!library.name || library.name.length === 0}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormControl className="select" fullWidth={true}>
                                                <InputLabel required={true} className="label-select">Línea de trabajo</InputLabel>
                                                <Select className="text-select" onChange={(value) => setLibrary({
                                                    ...library,
                                                    workLine: parseInt(value.target.value)
                                                })}
                                                    defaultValue={library.workLine}
                                                >
                                                    {
                                                        WE_DO.map(workLine => (
                                                            <MenuItem key={"workLine-" + workLine.id}
                                                                value={workLine.id} name={workLine.id}
                                                                id={workLine.id}>{workLine.text}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date"
                                                label="Fecha"
                                                format="MM/dd/yyyy"
                                                value={library.date}
                                                onChange={value => setLibrary({ ...library, date: value })}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className="calendar"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4} className="check-container">
                                            <Tooltip
                                                title={"Si este campo esta seleccionado, la "+nameLibrary+" aparecera para personas no registradas"}>
                                                <FormControlLabel
                                                    control={<GreenCheckbox name="checkApp" checked={library.isPublic}
                                                        onChange={value => setLibrary({
                                                            ...library,
                                                            isPublic: value.target.checked
                                                        })} />}
                                                    label="Pública"
                                                    style={{ color: 'white' }}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title="Si la biblioteca está activa será visible">
                                                <FormControlLabel
                                                    control={<GreenCheckbox name="checkState"
                                                        checked={library.state}
                                                        onChange={value => setLibrary({
                                                            ...library,
                                                            state: value.target.checked
                                                        })} />}
                                                    label="Activo"
                                                    style={{ color: 'white' }}
                                                />
                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </form>
                            <div className="space20px"></div>
                            <div onClick={saveLibrary}>
                                <Button type="button" color="success">Guardar {nameLibrary}</Button>
                                <Popover
                                    classes={{
                                        //paper: classes.popover
                                    }}
                                    open={Boolean(anchorElRight)}
                                    anchorEl={anchorElRight}
                                    onClose={() => setAnchorElRight(null)}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "right"
                                    }}
                                    transformOrigin={{
                                        vertical: "center",
                                        horizontal: "left"
                                    }}
                                >
                                    <div>
                                        {messageError}
                                    </div>
                                </Popover>
                            </div>
                            {id !== 'new' &&
                                <div>
                                    <Button type="button" color="success" onClick={() => loadFile()} >Cargar archivo</Button>
                                    <input ref={fileInputRef} type="file" style={{ display: "none" }} onChange={(event) => changeFile(event)} />
                                </div>
                            }
                            {id !== 'new' &&
                                <div className="library-tab-container">
                                    <div className="library-table">
                                        <Table
                                            minRows={5}
                                            columnNames={
                                                ["Archivo", "Acciones"]
                                            }
                                            data={
                                                files.map((file) => [<div className="card-title-library" onClick={() => window.open("https://storage.googleapis.com/acit/acit/" + file.id + file.extension, '_blank')}>{file.name}</div>,
                                                <Button size="sm" type="button" color="info" onClick={() => {
                                                    setIdDeleteAlert(file.id)
                                                }}>
                                                    <img src={deleteImage} alt={"image-" + file.id} />
                                                </Button>])
                                            } />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default LibraryForm;
