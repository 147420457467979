import './UserForm.css';
import background from "../../assets/img/backuser.jpg";
import Header from "../../header/Header";
import Button from "../../components/CustomButtons/Button";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, {useEffect} from 'react';
import "react-datetime/css/react-datetime.css";
import OptionService from "../../service/OptionService";
import UserService from "../../service/UserService";
import {useParams} from 'react-router-dom'
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';
import {sha256} from "js-sha256";
import Popover from "@material-ui/core/Popover";
import Tooltip from '@material-ui/core/Tooltip';

const idDocumentType = '5f6d529d8949473c032d501f';
const idGender = '5f988dc312c16b4db66ba76f';
const idMaritalStatus = '5f98c0430273399d28b8dad3';
const idResidentialLane = '5f98c13c0273399d28b8dad4';
const idProfile = '5f98c22c0273399d28b8dad8';

const GreenCheckbox = withStyles({
    root: {
        color: "#80CD33",
        '&$checked': {
            color: "#80CD33",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function UserForm(props) {

    const [user, setUser] = React.useState(null);
    const [documentTypes, setDocumentTypes] = React.useState(null);
    const [genders, setGenders] = React.useState(null);
    const [maritalStatuses, setMaritalStatuses] = React.useState(null);
    const [residentialLanes, setResidentialLanes] = React.useState(null);
    const [profiles, setProfiles] = React.useState(null);
    const [messageError, setMessageError] = React.useState(false);
    const [anchorElRight, setAnchorElRight] = React.useState(null);
    const {id} = useParams();

    useEffect(() => {
        OptionService.getOptions([idDocumentType, idGender, idMaritalStatus, idResidentialLane, idProfile])
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(json => {
                        setDocumentTypes(json.filter(item => item.idQuestion === idDocumentType));
                        setGenders(json.filter(item => item.idQuestion === idGender));
                        setMaritalStatuses(json.filter(item => item.idQuestion === idMaritalStatus));
                        setResidentialLanes(json.filter(item => item.idQuestion === idResidentialLane));
                        setProfiles(json.filter(item => item.idQuestion === idProfile));
                        if (id !== 'new') {
                            UserService.getUser(id).then((response) => {
                                if (response.status === 200) {
                                    response.json().then(json => {
                                        setUser(json)
                                    });
                                }
                            });
                        } else {
                            setUser({});
                        }
                    })
                }
            });
    }, [id]);

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const saveUser = (event) => {
        const newUser = validate();
        if (newUser) {
            let promise;
            if (id !== 'new') {
                promise = UserService.updateUser(id, newUser);
            } else {
                promise = UserService.createUser(newUser);
            }
            promise.then(response => {
                if (response.status === 200) {
                    props.history.push('/user');
                } else {
                    setMessageError("Hubo un error en el guardado.");
                    setAnchorElRight(event.currentTarget);
                }
            }).catch(() => {
                setMessageError("Hubo un error en el guardado.");
                setAnchorElRight(event.currentTarget);
            });
        } else {
            if (user.email && !re.test(user.email)) {
                setMessageError("El campo de email no corresponde a un correo.");
            } else {
                setMessageError("Faltan campos obligatorios.");
            }
            setAnchorElRight(event.currentTarget);
        }
    }

    const validate = () => {
        if (!user.name || user.name.length === 0) {
            return false;
        }
        if (!user.lastName || user.lastName.length === 0) {
            return false;
        }
        if (!user.identity || user.identity.length === 0) {
            return false;
        }
        if (!user.documentType) {
            return false;
        }
        if (!user.email || !re.test(user.email)) {
            return false;
        }
        const newUser = {...user};
        if (!user.accessApp) {
            newUser.accessApp = false;
        }
        if (!user.accessWeb) {
            newUser.accessWeb = false;
        }
        if (!user.active) {
            newUser.active = false;
        }
        if (user.password && user.password.length > 0) {
            newUser.password = sha256(user.password);
        }
        setUser(newUser);
        return newUser;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="main-container">
                <img src={background} className="image-background" alt="background"/>
                <Header title="Usuaria" subtitle={"Creacion nuevo usuaria"}/>
                {user && (
                    <div className="body-content">
                        <div className="tab-container-user">
                            <form className="user-form">
                                <h5 className="titlex">Información</h5>
                                <div className="trin">
                                    <GridContainer className="grid-container">
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Nombres"
                                                id="name"
                                                name='name'
                                                white={true}
                                                inputProps={{
                                                    defaultValue: user.name,
                                                    onChange: (value) => {
                                                        const newUser = {...user, name: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                                labelProps={{required: true}}
                                                error={!user.name || user.name.length === 0}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Apellidos"
                                                id="lastName"
                                                name='lastName'
                                                white={true}
                                                inputProps={{
                                                    defaultValue: user.lastName,
                                                    onChange: (value) => {
                                                        const newUser = {...user, lastName: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                                labelProps={{required: true}}
                                                error={!user.lastName || user.lastName.length === 0}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={3}>
                                            <FormControl className="select" fullWidth={true}>
                                                <InputLabel required={true} className="label-select">Tipo de
                                                    documento</InputLabel>
                                                <Select className="text-select" onChange={(value) => setUser({
                                                    ...user,
                                                    documentType: value.target.value
                                                })}
                                                        defaultValue={user.documentType}
                                                >
                                                    {
                                                        documentTypes.map(documentType => (
                                                            <MenuItem key={"documentType-" + documentType.id}
                                                                      value={documentType.id} name={documentType.id}
                                                                      id={documentType.id}>{documentType.name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Número de documento"
                                                id="identity"
                                                name='identity'
                                                required={true}
                                                white={true}
                                                inputProps={{
                                                    defaultValue: user.identity,
                                                    onChange: (value) => {
                                                        const newUser = {...user, identity: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                                labelProps={{required: true}}
                                                error={!user.identity || user.identity.length === 0}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="birthDate"
                                                label="Fecha de nacimiento"
                                                format="MM/dd/yyyy"
                                                value={user.birthDate}
                                                onChange={value => setUser({...user, birthDate: value})}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className="calendar"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Edad"
                                                id="age"
                                                name='age'
                                                white={true}
                                                inputProps={{
                                                    defaultValue: user.age,
                                                    onChange: (value) => {
                                                        const newUser = {...user, age: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <FormControl className="select" fullWidth={true}>
                                                <InputLabel className="label-select">Género</InputLabel>
                                                <Select className="text-select" onChange={(value) => setUser({
                                                    ...user,
                                                    gender: value.target.value
                                                })}
                                                        defaultValue={user.gender}
                                                >
                                                    {
                                                        genders.map(gender => (
                                                            <MenuItem key={"gender-" + gender.id}
                                                                      value={gender.id} name={gender.id}
                                                                      id={gender.id}>{gender.name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <FormControl className="select" fullWidth={true}>
                                                <InputLabel className="label-select">Estado
                                                    Civil</InputLabel>
                                                <Select className="text-select" onChange={(value) => setUser({
                                                    ...user,
                                                    maritalStatus: value.target.value
                                                })}
                                                        defaultValue={user.maritalStatus}
                                                >
                                                    {
                                                        maritalStatuses.map(maritalStatus => (
                                                            <MenuItem key={"maritalStatus-" + maritalStatus.id}
                                                                      value={maritalStatus.id} name={maritalStatus.id}
                                                                      id={maritalStatus.id}>{maritalStatus.name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <FormControl className="select" fullWidth={true}>
                                                <InputLabel className="label-select">Municipio de
                                                    residencia</InputLabel>
                                                <Select className="text-select" onChange={(value) => setUser({
                                                    ...user,
                                                    residentialLane: value.target.value
                                                })}
                                                        defaultValue={user.residentialLane}
                                                >
                                                    {
                                                        residentialLanes.map(residentialLane => (
                                                            <MenuItem key={"residentialLane-" + residentialLane.id}
                                                                      value={residentialLane.id}
                                                                      name={residentialLane.id}
                                                                      id={residentialLane.id}>{residentialLane.name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Lugar de residencia"
                                                id="residentialPlace"
                                                name='residentialPlace'
                                                white={true}
                                                inputProps={{
                                                    defaultValue: user.residentialPlace,
                                                    onChange: (value) => {
                                                        const newUser = {...user, residentialPlace: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Número de Celular"
                                                id="phone"
                                                name='phone'
                                                white={true}
                                                inputProps={{
                                                    defaultValue: user.phone,
                                                    onChange: (value) => {
                                                        const newUser = {...user, phone: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="beginDate"
                                                label="Ingreso al comité"
                                                format="MM/dd/yyyy"
                                                value={user.beginDate}
                                                onChange={value => setUser({...user, beginDate: value})}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className="calendar"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <FormControl className="select" fullWidth={true}>
                                                <InputLabel className="label-select">Perfil</InputLabel>
                                                <Select className="text-select" onChange={(value) => setUser({
                                                    ...user,
                                                    profile: value.target.value
                                                })}
                                                        defaultValue={user.profile}
                                                >
                                                    {
                                                        profiles.map(profile => (
                                                            <MenuItem key={"profile-" + profile.id}
                                                                      value={profile.id} name={profile.id}
                                                                      id={profile.id}>{profile.name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                        
                                                    
                                        
                                    </GridContainer>
                                </div>
                                <h5 className="titlex">Cuenta</h5>
                                <div className="trin">
                                    <GridContainer className="grid-container">
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Email"
                                                id="email"
                                                name='email'
                                                white={true}
                                                inputProps={{
                                                    type: 'email',
                                                    defaultValue: user.email,
                                                    onChange: (value) => {
                                                        const newUser = {...user, email: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                                labelProps={{required: true}}
                                                error={!user.email || user.email.length === 0}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Contraseña"
                                                id="password"
                                                name='password'
                                                white={true}
                                                inputProps={{
                                                    type: 'password',
                                                    defaultValue: user.password,
                                                    onChange: (value) => {
                                                        const newUser = {...user, password: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Confirmar contraseña"
                                                id="passwordConfirm"
                                                name='passwordConfirm'
                                                white={true}
                                                inputProps={{
                                                    type: 'password',
                                                    defaultValue: user.password,
                                                    onChange: (value) => {
                                                        const newUser = {...user, password: value.target.value};
                                                        setUser(newUser);
                                                    }
                                                }}
                                                //error={!user.password !== user.passwordConfirm}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4} className="check-container">
                                            <Tooltip
                                                title="Si este campo esta seleccionado, la usuaria podrá ingresar a la administración de la Web">
                                                <FormControlLabel
                                                    control={<GreenCheckbox name="checkWeb" checked={user.accessWeb}
                                                                            onChange={value => setUser({
                                                                                ...user,
                                                                                accessWeb: value.target.checked
                                                                            })}/>}
                                                    label="Web"
                                                    style={{color: 'white'}}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title="Si este campo esta seleccionado, la usuaria podrá ingresar a la App móvil">
                                                <FormControlLabel
                                                    control={<GreenCheckbox name="checkApp" checked={user.accessApp}
                                                                            onChange={value => setUser({
                                                                                ...user,
                                                                                accessApp: value.target.checked
                                                                            })}/>}
                                                    label="App"
                                                    style={{color: 'white'}}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title="Si la usuaria está activa podrá ingresar a la Web o la App móvil, si está inactiva no lo podrá hacer">
                                                <FormControlLabel
                                                    control={<GreenCheckbox name="checkState"
                                                                            checked={user.active}
                                                                            onChange={value => setUser({
                                                                                ...user,
                                                                                active: value.target.checked
                                                                            })}/>}
                                                    label="Activo"
                                                    style={{color: 'white'}}
                                                />
                                            </Tooltip>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </form>
                            <div className="space20px"></div>
                            <div onClick={saveUser}>
                                <Button type="button" color="success">Registrar Usuaria</Button>
                            </div>
                            <div>
                                <Button type="button" color="rose" href="/user">Cancelar</Button>
                            </div>
                            <Popover
                                classes={{
                                    //paper: classes.popover
                                }}
                                open={Boolean(anchorElRight)}
                                anchorEl={anchorElRight}
                                onClose={() => setAnchorElRight(null)}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right"
                                }}
                                transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left"
                                }}
                            >
                                <div>
                                    {messageError}
                                </div>
                            </Popover>
                        </div>
                    </div>
                )}
            </div>
        </MuiPickersUtilsProvider>
    )
}

export default UserForm;
