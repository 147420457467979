import './UserCard.css';
import React from 'react';
import "react-datetime/css/react-datetime.css";
import {Avatar, Card, CardContent, CardHeader} from "@material-ui/core";
import imageProfile from "../../assets/img/image_profile.jpg";

function UserCard(props) {

    const user = props.user;

    return (
        <div>
            <Card className="user-card">
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            <img src={imageProfile} style={{width: "100%"}} alt="profile" />
                        </Avatar>
                    }
                    title={user.name+' '+user.lastName}
                    subheader={user.email}
                    className="card-header"
                    subheaderTypographyProps={{color: "colorInherit"}}
                />
                <CardContent>
                    <div>
                        <div className="card-info">
                            <div className="card-title-user">Tipo de documento:</div>
                            <span className="card-data">{user.documentType}</span>
                        </div>
                        <div className="card-info">
                            <div className="card-title-user">Tipo de documento:</div>
                            <span className="card-data">Cedula</span>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default UserCard;
