import './Gallery.css';
import background from "../assets/img/backuser.jpg";
import Header from "../header/Header";
import Footer from "../footer/Footer";

import React, { useEffect } from 'react';
import GalleryDetail from './gallery-detail/GalleryDetail';
import Button from "../components/CustomButtons/Button";
import GalleryList from './gallery-list/GalleryList';

function Gallery(props) {

  const [logged, setLogged] = React.useState(false);
  const [galleryDetail, setGalleryDetail] = React.useState(null);
  const { idWorkLine } = props

  useEffect(() => {
    const token = localStorage.getItem('acit-token');
    if (token) setLogged(true);
  }, []);

  return (

    <div className="main-container">
      {!idWorkLine && <img src={background} className="image-background" alt="background" />}
      {!idWorkLine && <Header title="Galería" subtitle={"Fotos de los procesos del Comité de Mujeres de la ACIT"} />}
      <div className="body-content">
        {!galleryDetail && !idWorkLine && logged && <Button
          color="success"
          size="lg"
          href="/create-library/new/2"
          rel="noopener noreferrer"
          className="btnNewConversation"
        >
          Crear Galería
                </Button>}
        {!galleryDetail && <GalleryList idWorkLine={idWorkLine} onSelectGallery={(gallery) => setGalleryDetail(gallery)} />}
        {galleryDetail && <GalleryDetail gallery={galleryDetail} onCloseGallery={() => setGalleryDetail(null)} />}
      </div>
      {!idWorkLine && <Footer />}
    </div>
  )
}

export default Gallery;
