import './Header.css';
import Menu from "../menu/Menu";

function Header(props) {
    const {title, subtitle} = props;

    return (
        <div className="header-container">
            <Menu />
            <div className="header-title-container">
                <div className="header-title">{title}</div>
                <div className="header-subtitle">{subtitle}</div>
            </div>
        </div>
    );
}

export default Header;
