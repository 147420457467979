import React, { useEffect } from 'react';
import { PollAnswerService } from 'service/PollAnswerService';
import Table from 'table/Table';
import seeImage from "../../assets/img/visibility_24px_outlined.svg";
import Button from "../../components/CustomButtons/Button";

const PollAnswerTable = (props) => {

    const [pollAnswers, setPollAnswers] = React.useState([]);
    const { idPoll, idWorkLine, onSelectedPollAnswer } = props;
    

    useEffect(() => {
        setPollAnswers([]);
        PollAnswerService.getPollAnswersByWorkLine(idPoll, idWorkLine).then(
            response => response.json().then(json => {
                setPollAnswers(json);
            })
        )
    }, [idPoll, idWorkLine]);

    return (<Table columnNames={["Nombre"]}
        data={pollAnswers.map((item) => [
            <div className="poll-answer-row">
                <div className="poll-answer-text">{item.displayText}</div>
                <Button size="sm" type="button" color="primary" onClick={() => { onSelectedPollAnswer(item) }}><img src={seeImage} alt={"see-image-" + item.id} /></Button>
            </div>])} />);
}

export default PollAnswerTable;