import './DeleteAlert.css';
import Button from "../components/CustomButtons/Button";
const { Dialog, DialogTitle } = require("@material-ui/core");


function ErrorAlert(props) {

    const {open, onClose, message} = props

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{message}</DialogTitle>
            <div className="alert-button-group">
                <Button size="sm" type="button" color="danger" onClick={() => onClose()}>Acceptar</Button>
            </div>
        </Dialog>
    )
}

export default ErrorAlert;