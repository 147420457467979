import './DeleteAlert.css';
const { Dialog, DialogTitle } = require("@material-ui/core");


function WaitAlert(props) {

    const {open, message} = props

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{message}</DialogTitle>
        </Dialog>
    )
}

export default WaitAlert;