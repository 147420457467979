import './WeDoDetail.css';
import background from "../../assets/img/we_do/background_detail.png";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import React from 'react';
import { useParams } from 'react-router-dom';
import LibraryTable from './LibraryTable';
import Gallery from 'gallery/Gallery';
import PollAnswerTable from './PollAnswerTable';
import PollAnswerCard from 'poll-answer/poll-anwer-card/PollAnswerCard';

export const WE_DO = [
    {
        id: "1",
        text: "Fortalecimiento Político Organizativo",
        idWorkLine: "603926b11b71c75b98fa3b1a",
    },
    {
        id: "2",
        text: "Empoderamiento Femenino",
        idWorkLine: "60392c341b71c75b98fa3b1b",
    },
    {
        id: "3",
        text: "Derechos Humanos Con Énfasis en Derechos de las Mujeres.",
        idWorkLine: "60392c631b71c75b98fa3b1c",
    },
    {
        id: "4",
        text: "Soberania Alimentaria",
        idWorkLine: "60392c871b71c75b98fa3b1d",
    },
    {
        id: "5",
        text: "Propuestas Productivas Y Economia Solidaria",
        idWorkLine: "60392ca81b71c75b98fa3b1e",
    },
]

function WeDoDetail() {

    const [tabSelected, setTabSelected] = React.useState(0)
    const { id } = useParams();
    const section = WE_DO.find(item => item.id === id);
    const [selectedPollAnswer, setSelectedPollAnswer] = React.useState(null);

    return (
        <div className="main-container">
            <img src={background} className="image-background" alt="background" />
            <Header title={section.text} subtitle={"Nuestras Lineas de trabajo"} />
            {selectedPollAnswer && <div className="body-content">
                <PollAnswerCard pollAnswer={selectedPollAnswer} idPoll={tabSelected === 1 ? "6038126acafeab9fbe83f462" : "603812e1cafeab9fbe83f463"} goBack={() => setSelectedPollAnswer(null)} />
            </div>}
            {!selectedPollAnswer && <div className="body-content">
                <div className="tab-container">
                    <div className="tabs">
                        <div className={tabSelected === 0 ? "tab-selected" : "tab"} onClick={() => setTabSelected(0)}>Conversatorios</div>
                        <div className={tabSelected === 1 ? "tab-selected" : "tab"} onClick={() => setTabSelected(1)}>Talleres de formación</div>
                        <div className={tabSelected === 2 ? "tab-selected" : "tab"} onClick={() => setTabSelected(2)}>Galería</div>
                        <div className={tabSelected === 3 ? "tab-selected" : "tab"} onClick={() => setTabSelected(3)}>Biblioteca</div>
                    </div>
                    <div className="line"></div>
                    <div className="table">
                        {tabSelected !== 3 && tabSelected !== 2 &&
                            <PollAnswerTable idWorkLine={section.idWorkLine} idPoll={tabSelected === 1 ? "6038126acafeab9fbe83f462" : "603812e1cafeab9fbe83f463"} onSelectedPollAnswer={(item) => {
                                setSelectedPollAnswer(item)
                            }
                            } />
                        }
                        {
                            tabSelected === 3 &&
                            <LibraryTable idWorkLine={id} />
                        }
                        {
                            tabSelected === 2 &&
                            <Gallery idWorkLine={id} />
                        }
                    </div>
                </div>
            </div>}
            <Footer />
        </div>
    );
}

export default WeDoDetail;
