import Enviroment from './ConfigurationService';

const secureEndpoint = '/secure/answers';
const insecureEndpoint = '/answers';

export const PollAnswerService = {
    getPollAnswers: (idPoll, search) => {
        const limit = 1000;
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + secureEndpoint + '/poll/' + idPoll + '?page=' + 0 + '&size=' + limit + '&search=' + search.trim().replace(/ /g, '%20'), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    },

    getReadData: (idPollAnswer) => {
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + insecureEndpoint + '/poll-answer/' + idPollAnswer + '/read', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    },

    getPollAnswersByWorkLine: (idPoll, idWorkLine) => {
        const limit = 1000;
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + insecureEndpoint + '/poll/' + idPoll + '/work-line/' + idWorkLine + '?page=' + 0 + '&size=' + limit, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    },

    downloadUrl: (idPoll) => {
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + secureEndpoint + '/download/poll/' + idPoll, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    },

    downloadUrlOne: (idPollAnswer) => {
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + secureEndpoint + '/download/poll-answer/' + idPollAnswer, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    },
    
    deletePollAnswer: (idPollAnswer) => {
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + secureEndpoint + '/' + idPollAnswer, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    }
}