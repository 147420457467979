import './Library.css';
import background from "../assets/img/backuser.jpg";
import Header from "../header/Header";
import Button from "../components/CustomButtons/Button";
import React from 'react';
import Table from "../table/Table";
import LibraryService from "../service/LibraryService";
import Popover from "@material-ui/core/Popover";
import LibraryCard from "./library-card/LibraryCard";
import { WE_DO } from 'we-do/detail/WeDoDetail';
import seeImage from "../assets/img/visibility_24px_outlined.svg";
import newImage from "../assets/img/create_24px_outlined.svg";
import deleteImage from "../assets/img/delete_outline_24px_outlined.svg";
import DeleteAlert from 'alert/DeleteAlert';

function Library() {

    const [libraryList, setLibraryList] = React.useState([]);
    const [anchorElRight, setAnchorElRight] = React.useState([]);
    const refContainer = React.useRef();
    const [logged, setLogged] = React.useState(false);
    const [idDeleteAlert, setIdDeleteAlert] = React.useState(false);

    React.useEffect(() => {
        const token = localStorage.getItem('acit-token');
        if (token) setLogged(true);
        LibraryService.getLibraries(1, 0).then((response) => {
            if (response.status === 200) {
                response.json().then(json => {
                    const list = json.map(() => null);
                    setAnchorElRight(list);
                    setLibraryList(json);
                });
            }
        });
    }, []);

    const showLibrary = (event, index) => {
        const list = libraryList.map(() => null);
        list[index] = event.target;
        setAnchorElRight(list);
    }

    const getButtons = (library, index) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} key={'button-' + library.id}>
                <Button size="sm" type="button" color="primary" onClick={(event) => showLibrary(event, index)}><img src={seeImage} alt={"see-image-" + library.id} /></Button>
                <Popover
                    classes={{
                        //paper: classes.popover
                    }}
                    open={Boolean(anchorElRight[index])}
                    anchorEl={anchorElRight[index]}
                    onClose={() => {
                        const list = libraryList.map(() => null);
                        setAnchorElRight(list)
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right"
                    }}
                >
                    <LibraryCard library={library} />
                </Popover>
                { logged && <Button size="sm" type="button" color="info" href={"/create-library/" + library.id + "/1"}><img src={newImage} alt={"new-image-" + library.id} /></Button>}
                { logged && <Button size="sm" type="button" color="danger" onClick={() => setIdDeleteAlert(library.id)}><img src={deleteImage} alt={"delete-image-" + library.id} /></Button>}
            </div>);
    }

    return (
        <div ref={refContainer} className="library-main-container">
            <DeleteAlert open={!!idDeleteAlert} onClose={(canDelete) => {
                if (canDelete) {
                    LibraryService.deleteLibrary(idDeleteAlert).then(response => {
                        if (response.status === 200) {
                            const index = libraryList.findIndex(item => item.id === idDeleteAlert);
                            libraryList.splice(index, 1)
                            setLibraryList([...libraryList]);
                        }
                    })
                }
                setIdDeleteAlert(null)
            }} />
            <img src={background} className="library-image-background" alt="background" />
            <Header title="Biblioteca" subtitle={"Documentos de usuarias"} />
            <div className="library-body-content">
                {logged && <Button
                    color="success"
                    size="lg"
                    href="/create-library/new/1"
                    rel="noopener noreferrer"
                    className="btnNewConversation"
                >
                    Crear Biblioteca
                </Button>}
                <div className="library-tab-container">
                    <div className="library-table">
                        <Table
                            columnNames={
                                ["Nombre", "Línea de trabajo", "Acciones"]
                            }
                            data={
                                libraryList.map((library, index) =>
                                    [library.name, WE_DO.find(item => parseInt(item.id) === library.workLine).text, getButtons(library, index)]
                                )
                            } />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Library;
