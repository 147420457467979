import LibraryService from 'service/LibraryService';
import LibraryCard from 'library/library-card/LibraryCard';
import seeImage from "../../assets/img/visibility_24px_outlined.svg";
import Popover from "@material-ui/core/Popover";
import Button from "../../components/CustomButtons/Button";
import React, { useEffect } from 'react';
import Table from 'table/Table';


const LibraryTable = (props) => {
    const [libraryList, setLibraryList] = React.useState([]);
    const [anchorElRight, setAnchorElRight] = React.useState([]);
    const {idWorkLine} = props

    useEffect(() => {
        LibraryService.getLibrariesByWorkLine(1, idWorkLine, 0).then((response) => {
            if (response.status === 200) {
                response.json().then(json => {
                    const list = json.map(() => null);
                    setAnchorElRight(list);
                    setLibraryList(json);
                });
            }
        });
    }, [idWorkLine])

    const showLibrary = (event, index) => {
        const list = libraryList.map(() => null);
        list[index] = event.target;
        setAnchorElRight(list);
    }

    const getButtons = (library, index) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} key={'button-' + library.id}>
                <Button size="sm" type="button" color="primary" onClick={(event) => showLibrary(event, index)}><img src={seeImage} alt={"see-image-" + library.id} /></Button>
                <Popover
                    classes={{
                        //paper: classes.popover
                    }}
                    open={Boolean(anchorElRight[index])}
                    anchorEl={anchorElRight[index]}
                    onClose={() => {
                        const list = libraryList.map(() => null);
                        setAnchorElRight(list)
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "right"
                    }}
                >
                    <LibraryCard library={library} />
                </Popover>
            </div>);
    }

    return (
        <Table
            columnNames={
                ["Nombre", "Acciones"]
            }
            data={
                libraryList.map((library, index) =>
                    [library.name, getButtons(library, index)]
                )
            } />
    );
}

export default LibraryTable;