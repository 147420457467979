import Enviroment from './ConfigurationService';

const endpoint = '/secure/users';

const UserService = {
  login: (email, password) => {
    return fetch(Enviroment.URL + '/users/login', {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  getUsers: (page) => {
    const limit = 1000;
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + endpoint + '?page=' + page + '&size=' + limit, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  getUser: (id) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + endpoint + '/' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  createUser: (user) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + endpoint, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  updateUser: (id, user) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + endpoint + '/' + id, {
      method: 'PUT',
      body: JSON.stringify(user),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  downloadUsers: () => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + endpoint + '/download', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  downloadUser: (idUser) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + endpoint + '/download/user/' + idUser, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },
};

export default UserService;
