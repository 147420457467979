import './WeDo.css';
import background from "../assets/img/background_we_do.png";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Fingerprint from '@material-ui/icons/Policy';
import EmojiNature from '@material-ui/icons/PregnantWoman';
import Kitchen from '@material-ui/icons/NaturePeople';
import LocalFlorist from '@material-ui/icons/Spa';
import PollIcon from '@material-ui/icons/Poll';
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import {useTheme} from '@material-ui/core/styles';
import {Link} from "react-router-dom";

const VIEWS = {
    option1: {
        title: "Fortalecimiento Político Organizativo",
        titleTextColor: "#DADE8E",
        textColor: "rgba(255, 255, 255, 0.75)",
        colorTitle: "rgba(67, 145, 41, 0.85)",
        color: "rgba(80, 31, 97, 0.7)",
        text: "Es un proceso de construcción colectiva que se da mediante la organización individual y grupal de las  mujeres en las diferentes comunidades, donde se generan espacios de  fortalecimiento de la identidad campesina, formación integral, sensibilización en temas de género y movilización de la participación social y política de las mujeres para generar transformaciones y avanzar en la lucha por el reconocimiento de las mujeres campesinas como sujetas políticas de derechos."
    },
    option2: {
        title: "Derechos Humanos Con Énfasis en Derechos de las Mujeres",
        titleTextColor: "#DADE8E",
        textColor: "rgba(255, 255, 255, 0.75)",
        colorTitle: "rgba(67, 145, 41, 0.85)",
        color: "rgba(80, 31, 97, 0.7)",
        text: "Es un proceso de formación que permite conocer y reconocer los derechos humanos con enfoque de género, como herramientas de exigibilidad; que permite tanto el goce efectivo de los mismos como las transformaciones en todos los escenarios que habitan y comparten las mujeres campesinas. "
    },
    option3: {
        title: "Empoderamiento femenino",
        titleTextColor: "#DADE8E",
        textColor: "rgba(255, 255, 255, 0.75)",
        colorTitle: "rgba(67, 145, 41, 0.85)",
        color: "rgba(80, 31, 97, 0.7)",
        text: "Es una estrategia que permite el auto reconocimiento de las mujeres, el liderazgo, e incentiva el poder interno de las mujeres para sus transformaciones,   se realiza mediante los espacios de formación política, de reflexión en torno a los feminismos, los derechos humanos y la identidad campesina, mediante el desarrollo de habilidades que les permita asumir su vida con autonomía, determinación, equidad, crítica y toma de decisiones individuales y colectivas."
    },
    option4: {
        title: "Soberania Alimentaria",      
        titleTextColor: "#DADE8E",
        textColor: "rgba(255, 255, 255, 0.75)",
        colorTitle: "rgba(67, 145, 41, 0.85)",
        color: "rgba(80, 31, 97, 0.7)",
        text: "Esta línea de trabajo se centra en el fortalecimiento de las prácticas productivas desde la premisa de producción agroecológica en defensa del territorio y los recursos naturales. Además desde la resistencia frente a la recuperación, cuidado y conservación de las semillas nativas y criollas."
    },
    option5: {     
        title: "Propuestas Productivas Y Economia Solidaria",
        titleTextColor: "#DADE8E",
        textColor: "rgba(255, 255, 255, 0.75)",
        colorTitle: "rgba(67, 145, 41, 0.85)",
        color: "rgba(80, 31, 97, 0.7)",
        text: "Es una apuesta que busca incentivar la creación de iniciativas económicas populares, como las parcelas productivas, tiendas comunitarias, mercados locales campesinos, pero también la cultura de ahorro mediante los grupos de ahorro y crédito local, la Pre Cooperativa de ahorro y crédito de Inzá  y otras actividades económicas."
    }
}

function a11yProps(index, scroll) {
    if (scroll)
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function WeDo() {

    const [value, setValue] = React.useState(0);
    const refContainer = React.useRef();
    const [widthContainer, setWidthContainer] = React.useState(null);
    const theme = useTheme();

    React.useEffect(() => {
        setWidthContainer(refContainer.current.clientWidth);
    }, []);
 
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className="main-container">
            <img src={background} className="image-background" alt="background" />
            <Header title={"¿Qué hacemos?"} subtitle={"Nuestras Lineas de trabajo"} />
            <div className="body-content" ref={refContainer}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="primary"
                        variant={widthContainer >= 800 ? "fullWidth" : "scrollable"}
                        scrollButtons="on"
                        aria-label="Lineas de trabajo"
                    >
                        <Tab label={VIEWS.option1.title} icon={<Fingerprint />} style={{
                            backgroundColor: VIEWS.option1.colorTitle,
                            color: VIEWS.option1.titleTextColor,                            
                            fontSize: 13,
                            fontFamily: 'Comfortaa', 
                        }} {...a11yProps(0, widthContainer < 800)} />
                        <Tab label={VIEWS.option2.title} icon={<EmojiNature />} style={{
                            backgroundColor: VIEWS.option2.colorTitle,                                                        
                            color: VIEWS.option2.titleTextColor,
                            fontSize: 13,
                            fontFamily: 'Comfortaa',                 
                        }} {...a11yProps(1, widthContainer < 800)} />
                        <Tab label={VIEWS.option3.title} icon= {<LocalFlorist />} style={{
                            backgroundColor: VIEWS.option3.colorTitle,
                            color: VIEWS.option3.titleTextColor,                                                        
                            fontFamily: 'Comfortaa', 
                        }} {...a11yProps(2, widthContainer < 800)} />
                        <Tab label={VIEWS.option4.title} icon={<Kitchen />} style={{
                            backgroundColor: VIEWS.option4.colorTitle,
                            color: VIEWS.option4.titleTextColor,                            
                            fontFamily: 'Comfortaa', 
                        }} {...a11yProps(3, widthContainer < 800)} />
                        <Tab label={VIEWS.option5.title} icon={<PollIcon />} style={{
                            backgroundColor: VIEWS.option5.colorTitle,
                            color: VIEWS.option5.titleTextColor,
                            fontSize: 13,
                            fontFamily: 'Comfortaa',   
                        }} {...a11yProps(4, widthContainer < 800)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <div className="detail-text" style={{
                            backgroundColor: VIEWS.option1.color,
                            color: VIEWS.option1.textColor,                            
                            fontSize: widthContainer < 800 ? 15 : 25,
                            textAlign:'Justify',
                            paddingLeft: 35,
                            paddingRight: 35,                                
                        }}>
                            <div style={{margin: 'auto'}}>
                                {VIEWS.option1.text}
                                <Link to={'/we-do-detail/1'} className="link">
                                    <input type="button"
                                        value="Ver Más"                                   
                                        className="view-more-button"/>
                                </Link>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <div className="detail-text" style={{
                            backgroundColor: VIEWS.option2.color,
                            color: VIEWS.option2.textColor,
                            fontSize: widthContainer < 800 ? 15 : 25,
                            textAlign:'Justify',
                            paddingLeft: 35,
                            paddingRight: 35   
                        }}>
                            <div style={{margin: 'auto'}}>
                                {VIEWS.option2.text}
                                <Link to={'/we-do-detail/3'} className="link">
                                    <input type="button"
                                        value="Ver Más"                                   
                                        className="view-more-button"/>
                                </Link>
                                </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <div className="detail-text" style={{
                            backgroundColor: VIEWS.option3.color,
                            color: VIEWS.option3.textColor,
                            fontSize: widthContainer < 800 ? 15 : 25,
                            textAlign:'Justify',
                            paddingLeft: 35,
                            paddingRight: 35   
                        }}>
                            <div style={{margin: 'auto'}}>
                                {VIEWS.option3.text}
                                <Link to={'/we-do-detail/2'} className="link">
                                    <input type="button"
                                        value="Ver Más"                                   
                                        className="view-more-button"/>
                                </Link>
                                </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <div className="detail-text" style={{
                            backgroundColor: VIEWS.option4.color,
                            color: VIEWS.option4.textColor,
                            fontSize: widthContainer < 800 ? 15 : 25,
                            textAlign:'Justify',
                            paddingLeft: 35,
                            paddingRight: 35                                                    
                        }}>
                            <div style={{margin: 'auto'}}>
                                {VIEWS.option4.text}
                                <Link to={'/we-do-detail/4'} className="link">
                                    <input type="button"
                                        value="Ver Más"                                   
                                        className="view-more-button"/>
                                </Link>
                                </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={4} dir={theme.direction}>
                        <div className="detail-text" style={{
                            backgroundColor: VIEWS.option5.color,
                            color: VIEWS.option5.textColor,
                            fontSize: widthContainer < 800 ? 15 : 25,
                            textAlign:'Justify',
                            paddingLeft: 35,
                            paddingRight: 35                                              
                        }}>
                            <div style={{margin: 'auto'}}>
                                {VIEWS.option5.text}
                                <Link to={'/we-do-detail/5'} className="link">
                                    <input type="button"
                                        value="Ver Más"                                   
                                        className="view-more-button"/>
                                </Link>
                                </div>
                        </div>
                    </TabPanel>
                </SwipeableViews>               
            </div>
            <Footer />
        </div>
    );
}

export default WeDo;