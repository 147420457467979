import './GalleryList.css';
import React, { useEffect } from "react";
import LibraryService from "service/LibraryService";
import DateFnsUtils from '@date-io/date-fns';
import emptyImage from "../../assets/img/grid_off_24px_outlined.svg";
import newImage from "../../assets/img/create_24px_outlined.svg";
import deleteImage from "../../assets/img/delete_outline_24px_outlined.svg";
import Button from "../../components/CustomButtons/Button";
import DeleteAlert from 'alert/DeleteAlert';

const GalleryList = (props) => {

    const { idWorkLine, onSelectGallery } = props
    const [galleries, setGalleries] = React.useState([]);
    const dateFnsUtils = new DateFnsUtils();
    const [logged, setLogged] = React.useState(false);
    const [idDeleteAlert, setIdDeleteAlert] = React.useState(false);

    useEffect(() => {
        const token = localStorage.getItem('acit-token');
        if (token) setLogged(true);
        if (idWorkLine) {
            LibraryService.getLibrariesByWorkLine(2, idWorkLine, 0).then(respose => manageResponse(respose));
        } else {
            LibraryService.getLibraries(2, 0).then(respose => manageResponse(respose));
        }
    }, [idWorkLine])

    const manageResponse = (response) => {
        if (response.status === 200) {
            response.json().then(json => {
                setGalleries(json);
            });
        }
    }

    return (<div className="main-gallery-container">
        <DeleteAlert open={!!idDeleteAlert} onClose={(canDelete) => {
                if (canDelete) {
                    LibraryService.deleteLibrary(idDeleteAlert).then(response => {
                        if(response.status === 200) {
                            const index = galleries.findIndex(item => item.id === idDeleteAlert);
                            galleries.splice(index, 1)
                            setGalleries([...galleries]);
                        }
                    })
                }
                setIdDeleteAlert(null)
            }} />
        { galleries.map((gallery) =>
            <div key={gallery.id} className="gallery-container" style={{cursor: gallery.firstLibraryFile?"pointer":"default"}} onClick={() => {
                if(gallery.firstLibraryFile) {
                    onSelectGallery(gallery)
                }
            }}>
                {logged && !idWorkLine &&<Button className="gallery-button-edit" size="sm" type="button" color="info" href={"/create-library/" + gallery.id + "/2"}>
                    <img alt={"edit-"+gallery.id} src={newImage} />
                </Button>}
                {logged && !idWorkLine &&<Button className="gallery-button-delete" size="sm" type="button" color="danger" onClick={(event) => {
                    setIdDeleteAlert(gallery.id)
                    event.preventDefault()
                    event.stopPropagation()
                }}>
                    <img alt={"delete-"+gallery.id} src={deleteImage} />
                </Button>}
                <div className="gallery-image-container">
                    {!gallery.firstLibraryFile && <img src={emptyImage} className="gallery-load-image" alt={"empty-" + gallery.id} />}
                    {gallery.firstLibraryFile && <img src={"https://storage.googleapis.com/acit/acit/"+gallery.firstLibraryFile.id+gallery.firstLibraryFile.extension} className="gallery-image" alt={"empty-" + gallery.id} />}
                </div>
                <div className="gallery-text-info">
                    <div>{gallery.name}</div>
                    <div>{dateFnsUtils.format(new Date(gallery.date), "dd/MM/yyyy")}</div>
                </div>
            </div>)}
    </div>)
}

export default GalleryList;