import Enviroment from './ConfigurationService';

const endpoint = '/poll-modules';

export const PollModuleService = {
    getPollModules: (idPoll) => {
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + endpoint + '/poll/' + idPoll, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    }
}

export default PollModuleService;