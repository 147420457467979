import './DeleteAlert.css';
import Button from "../components/CustomButtons/Button";
const { Dialog, DialogTitle } = require("@material-ui/core");


function DeleteAlert(props) {

    const {open, onClose} = props

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">¿Está segura de querer eliminar el registro?</DialogTitle>
            <div className="alert-button-group">
                <Button size="sm" type="button" color="info" onClick={() => onClose(false)}>Cancelar</Button>
                <Button size="sm" type="button" color="danger" onClick={() => onClose(true)}>Eliminar</Button>
            </div>
        </Dialog>
    )
}

export default DeleteAlert;