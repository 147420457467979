import './App.css';
import background from "../assets/img/background.jpg";
import icon from "../assets/img/icon_app.png";
import Menu from "../menu/Menu";
import {Link} from "react-router-dom";
import Footer from "../footer/Footer";

function App() {
  return (
    <div className="main-container">
      <img src={background} className="image-background" alt="background" />
      <div className="header">
        <Menu />
      </div>
      <div className="name-bar">
          <div className="title">Comité de mujeres ACIT</div>
          <div className="sub-title">Mujeres Campesinas en junta, por un buen vivir</div>
      </div>
      <div className="footer">
          <div className="footer-button">
              <Link to={'/about'} className="link">
                  <div className="button-flex">
                      <img src={icon} alt="who" />
                      <div className="button-text">¿Quiénes Somos?</div>
                  </div>
              </Link>
              <Link to={'/we-do'} className="link">
                  <div className="button-flex">
                      <img src={icon} alt="do" />
                      <div className="button-text">¿Que Hacemos?</div>
                  </div>
              </Link>
          </div>
          <Footer />
      </div>
    </div>
  );
}

export default App;
