import './Table.css'

function Table(props) {

    const {columnNames, data} = props
    let minRows = props.minRows;

    if(!minRows) {
        minRows = 0;
    }

    return (
        <div className="table-container">
            <div className="column-title">
                {columnNames.map((item, index) => <div className="column" key={"title-"+index}>{item}</div>)}
            </div>
            <div className="table-data">
                {
                    data.map((item, index) => (
                        <div className="table-row" key={"row-"+index}>
                            {
                                item.map((data, indexColumn) => <div key={"data-"+((index*item.length)+indexColumn)} className="column">{data}</div>)
                            }
                        </div>
                    ))
                }
                {
                    Array(Math.max(0, minRows - data.length)).fill(i => data.length + i).map(
                        (item, index) => {
                            return <div className="table-row" key={"row-" + (index + data.length)}/>
                        }
                    )
                }
            </div>
        </div>
    )
}

export default Table;
