import './Footer.css';
import contact from '../assets/img/expand_more_24px.png'
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className="footer-container">
            <Link className="link" to='/contact'>
                <div className="footer-text">Contáctanos</div>
                <img src={contact} alt="contact" />
            </Link>
        </div>
    );
}

export default Footer;
