import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './main/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AboutUs from "./about-us/AboutUs";
import WeDo from "./we-do/WeDo";
import WeDoDetail from "./we-do/detail/WeDoDetail";
import HomeAcitProp2App from "./HomeAcitProp2/HomeAcitProp2App";
import User from "./user/User";
import CreateUser from "./user/userForm/UserForm";
import Gallery from "./gallery/Gallery";
import Library from 'library/Library';
import LibraryForm from 'library/library-form/LibraryForm';
import PollAnswer from 'poll-answer/PollAnswer';
import Contact from 'contact/Contact';

const Routing = () => {
  return(
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
          <Route exact path="/prop-2" component={HomeAcitProp2App} />
        <Route path="/about" component={AboutUs} />
        <Route path="/we-do" component={WeDo} />
        <Route path="/we-do-detail/:id" component={WeDoDetail} />
        <Route path="/poll-answer/:id" component={PollAnswer} />
        <Route path="/user" component={User} />
        <Route path="/create-user/:id" component={CreateUser} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/library" component={Library} />
        <Route path="/create-library/:id/:libraryType" component={LibraryForm} />
        <Route path="/contact" component={Contact} />
      </Switch>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
