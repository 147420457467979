import './PollAnswer.css';
import background from "../assets/img/workshop.jpg";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Button from "../components/CustomButtons/Button";
import React, { useEffect, useRef, useState } from 'react';
import Table from "../table/Table";
import { useParams } from 'react-router';
import { PollAnswerService } from 'service/PollAnswerService';
import seeImage from "../assets/img/visibility_24px_outlined.svg";
import { IconButton, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import deleteImage from "../assets/img/delete_outline_24px_outlined.svg";
import downloadImage from "../assets/img/download_24px_outlined.svg";
import DeleteAlert from 'alert/DeleteAlert';
import PollAnswerCard from './poll-anwer-card/PollAnswerCard';

const POLL_TITLE = {
    "5f6d31868949473c032d5011": "Encuestas de caracterización",
    "5f727175d7277cc078f40258": "Acompañamientos",
    "6038126acafeab9fbe83f462": "Talleres de formación",
    "603812e1cafeab9fbe83f463": "Conversatorios",
}

const POLL_TITLE_COLUMNS = {
    "5f6d31868949473c032d5011": ["Nombres", "Apellidos", "Número de documento de Identificación"],
    "5f727175d7277cc078f40258": ["Ubicación", "Beneficiaria"],
    "6038126acafeab9fbe83f462": ["Nombre"],
    "603812e1cafeab9fbe83f463": ["Nombre"]
}

function PollAnswer() {

    const { id } = useParams();
    const [pollAnswers, setPollAnswers] = useState([]);
    const textRef = useRef();
    const [textSearch, setTextSearch] = useState("");
    const [selectedPollAnswer, setSelectedPollAnswer] = useState(null);
    const [idDeleteAlert, setIdDeleteAlert] = React.useState(false);

    useEffect(() => {
        setPollAnswers([]);
        PollAnswerService.getPollAnswers(id, "").then(
            response => response.json().then(json => {
                setPollAnswers(json);
            })
        )
    }, [id]);

    const search = (txt) => {
        PollAnswerService.getPollAnswers(id, txt).then(
            response => response.json().then(json => setPollAnswers(json))
        )
    }

    const showPollAnswer = (pollAnswer) => {
        setSelectedPollAnswer(pollAnswer);
    }

    return (
        <div className="main-container">
            <DeleteAlert open={!!idDeleteAlert} onClose={(canDelete) => {
                if (canDelete) {
                    PollAnswerService.deletePollAnswer(idDeleteAlert).then(response => {
                        if (response.status === 200) {
                            const index = pollAnswers.findIndex(item => item.id === idDeleteAlert);
                            pollAnswers.splice(index, 1)
                            setPollAnswers([...pollAnswers]);
                        }
                    })
                }
                setIdDeleteAlert(null)
            }} />
            <img src={background} className="image-background" alt="background" />
            <Header title={POLL_TITLE[id]} subtitle={"Realizados por el Comité de Mujeres de la ACIT"} />
            {selectedPollAnswer && <div className="body-content">
                <PollAnswerCard pollAnswer={selectedPollAnswer} idPoll={id} goBack={() => setSelectedPollAnswer(null)} />
            </div>}
            {!selectedPollAnswer && <div className="body-content">
                <Paper component="div" className="poll-answer-search-root">
                    <InputBase
                        ref={textRef}
                        className="poll-answer-search-input"
                        placeholder="Buscar"
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                search(textSearch);
                            }
                        }}
                        value={textSearch}
                        onChange={(event) => setTextSearch(event.target.value)}
                    />
                    <IconButton type="button" className="poll-answer-search-icon" aria-label="search" color="primary" onClick={() => search(textSearch)}>
                        <SearchIcon color="disabled" />
                    </IconButton>
                    <IconButton type="button" className="poll-answer-search-icon" aria-label="search" color="primary" onClick={() => {
                        setTextSearch('')
                        search('')
                    }}>
                        <CloseIcon color="disabled" />
                    </IconButton>
                    {
                        <Button size="sm" type="button" color="primary" onClick={(event) => {
                            PollAnswerService.downloadUrl(id).then(response => {
                                response.text().then(url => {
                                    window.open("https://storage.googleapis.com/acit/" + url, '_blank');
                                });
                            })
                        }}>Descargar</Button>}
                </Paper>
                <div className="tab-container">
                    <div className="line"></div>
                    <div className="table">
                        {<Table columnNames={[POLL_TITLE_COLUMNS[id].reduce((prev, item) => prev + ' - ' + item)]}
                            data={pollAnswers.map((item, index) => [
                                <div className="poll-answer-row">
                                    <div className="poll-answer-text">{item.displayText}</div>
                                    <Button size="sm" type="button" color="primary" onClick={(event) => { showPollAnswer(item) }}><img src={seeImage} alt={"see-image-" + item.id} /></Button>
                                    {<Button size="sm" type="button" color="danger" onClick={() => setIdDeleteAlert(item.id)}><img src={deleteImage} alt={"delete-image-" + item.id} /></Button>}
                                    <Button size="sm" type="button" color="info" onClick={() => {
                                        PollAnswerService.downloadUrlOne(item.id).then(response => {
                                            response.text().then(url => {
                                                window.open("https://storage.googleapis.com/acit/" + url, '_blank');
                                            });
                                        })
                                    }}><img src={downloadImage} alt={"download-image-" + item.id} /></Button>
                                </div>])} />
                        }
                    </div>
                </div>
            </div>}
            <Footer />
        </div>
    );
}

export default PollAnswer;
