import './LibraryCard.css';
import React from 'react';
import "react-datetime/css/react-datetime.css";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import { WE_DO } from 'we-do/detail/WeDoDetail';
import LibraryService from 'service/LibraryService';

function LibraryCard(props) {

    const library = props.library;
    const [files, setFiles] = React.useState([]);

    React.useEffect(() => {
        LibraryService.getFiles(library.id)
        .then((response) => response.json().then(json => {
            setFiles(json.content)
        }))
    }, [library])

    return (
        <div>
            <Card className="library-card">
                <CardHeader
                    title={library.name}
                    subheader={WE_DO.find(item => parseInt(item.id) === library.workLine).text}
                    className="card-header"
                    subheaderTypographyProps={{color: "colorInherit"}}
                />
                <CardContent>
                    <div>
                        {files.map((item) => 
                            (<div className="card-info">
                                <div className="card-title-library" onClick={() => window.open("https://storage.googleapis.com/acit/acit/"+item.id+item.extension, '_blank')}>{item.name}</div>
                            </div>)
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default LibraryCard;
