import Enviroment from './ConfigurationService';

const secureEndpoint = '/secure/libraries';
const insecureEndpoint = '/libraries';

const LibraryService = {
  getLibraries: (libraryType, page) => {
    const limit = 1000;
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + insecureEndpoint + '/library-type/' + libraryType + '?page=' + page + '&size=' + limit, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  getLibrariesByWorkLine: (libraryType, workLine, page) => {
    const limit = 1000;
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + insecureEndpoint + '/library-type/' + libraryType + '/work-line/' + workLine + '?page=' + page + '&size=' + limit, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  getLibrary: (id) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + insecureEndpoint + '/' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  createLibrary: (library) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + secureEndpoint, {
      method: 'POST',
      body: JSON.stringify(library),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  updateLibrary: (id, library) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + secureEndpoint + '/' + id, {
      method: 'PUT',
      body: JSON.stringify(library),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  uploadFile: (idLibrary, file) => {
    const formData = new FormData();
    formData.append("file", file);
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + secureEndpoint + '/upload-file/' + idLibrary, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  getFiles: (idLibrary) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + insecureEndpoint + '/files/' + idLibrary, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  deleteFile: (idLibraryFile) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + secureEndpoint + '/delete-file/' + idLibraryFile, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  },

  deleteLibrary: (idLibrary) => {
    const token = localStorage.getItem('acit-token');
    return fetch(Enviroment.URL + secureEndpoint + '/' + idLibrary, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  }
};

export default LibraryService;
