import Enviroment from './ConfigurationService';

const endpoint = '/secure/options';

const OptionService = {
    getOptions: (idQuestions) => {
        const token = localStorage.getItem('acit-token');
        return fetch(Enviroment.URL + endpoint + '/all/' + idQuestions.join(','), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        });
    },
};

export default OptionService;
